#TopBar
    display: flex
    justify-content: center
    align-items: center
    width: 100%
    height: 100px

    #LogoTV5
        width: 80px
        height: 80px

    #ImageSettings
        position: absolute
        top: 30px
        right: 30px
        width: 35px
        height: 35px
