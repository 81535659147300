#Loader
    display: flex
    justify-content: center
    align-items: center
    width: 100vw
    height: 100vh

    #GifLoader
        width: 300px
        height: 300px
