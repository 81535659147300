#ActivationPage
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    width: 100%
    font-family: Shape
    color: #ffffff

    #TitleActivation
        max-width: 354px
        width: 90%
        font-size: 22px
        margin-top: 50px
        margin-bottom: 50px
        text-align: center

    .textActivation
        max-width: 354px
        width: 90%
        font-size: 18px
        text-align: center
        color: #ffffff
        font-family: Shape

    #InputActivation
        max-width: 354px
        width: 80%
        height: 60px
        margin-top: 20px
        padding-right: 20px
        padding-left: 20px
        font-size: 16px
        font-weight: bold
        background-color: rgb(20,19,48)
        border: 1px solid transparent
        border-radius: 8px
        border-color: #00b2a9
        outline: none
        -webkit-appearance: none
        color: #ffffff

    #ButtonActivation
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        width: 200px
        height: 44px
        background-color: #00b2a9
        border: 1px solid transparent
        border-radius: 8px
        align-self: center
        margin-top: 30px
        outline: none
        &:active
            box-shadow: 1px 1px 1px 1px grey
