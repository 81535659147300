#PopUp
    position: absolute
    display: flex
    justify-content: center
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.75)

    #FramePopUp
        display: flex
        margin-top: 200px
        justify-content: center
        align-items: center
        flex-direction: column
        width: 500px
        height: 200px
        background-color: #00b1a8
        border-radius: 16px

        #TitlePopUp
            font-family: Shape
            font-size: 20px
            text-align: center
            color: #ffffff

        #ButtonPopUp
            margin-top: 40px
            width: 150px
            height: 50px
            border-radius: 16px
            border: none
            background-color: #ffffff
            font-size: 17px
            font-family: Shape

        #TextButtonPopUp
            font-family: Shape
            font-size: 18px
            text-align: center
            font-weight: bold
            color: black
