#ProvidersListPage
    display: flex
    flex-direction: column
    width: 50vw
    align-items: center

    #ArrowImage
        position: absolute
        top: 120px
        left: 50px

    .titleProvidersList
        font-size: 20px
        font-family: Shape
        font-weight: bold
        color: #ffffff
        margin-top: 50px
        text-align: center

    #ProvidersList
        display: flex
        width: 80vw
        flex-wrap: wrap
        flex-direction: row
        justify-content: center
        margin-top: 50px

        .provider
            display: flex
            flex-direction: column
            align-items: center
            flex-wrap: wrap
            margin: 30px

        .textProvider
            color: #FFFFFF
            font-family: Shape
            font-size: 20px
            font-weight: bold
            width: 280px
            text-align: center

        .logoProvider
            justify-content: center
            align-items: center
            background-color: white
            border-radius: 16px
            width: 150px
            height: 70px
            margin-bottom: 5px
            padding: 10px

            .imageProvider
                width: 100%
                height: 100%
                object-fit: contain
