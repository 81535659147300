#SettingsPage

    #ArrowImage
        position: absolute
        top: 120px
        left: 50px

    #LanguageList
        margin-top: 80px

        #TitleLanguageList
            font-size: 20px
            font-family: Shape
            font-weight: bold
            color: #ffffff
            margin-bottom: 50px
            text-align: center

        .buttonLanguage
            display: flex
            flex-direction: column
            justify-content: center
            align-items: center
            width: 200px
            height: 44px
            background-color: #00b2a9
            border: 1px solid transparent
            border-radius: 8px
            align-self: center
            margin-top: 30px
            outline: none

        .textButtonLanguage
            max-width: 354px
            width: 90%
            font-size: 18px
            font-family: Shape
            color: #ffffff
            text-align: center
